<template>
    <div>
        <ValidationObserver ref="formValidate">
            <b-row>
                <b-col md="4">
                    <ValidationProvider name="personal_email" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('personal_email')">
                            <b-form-input v-model="formData.personal_email"
                                          :placeholder="$t('personal_email')"
                                          :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="graduate_email" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('graduate_email')">
                            <b-form-input v-model="formData.graduate_email"
                                          :placeholder="$t('graduate_email')"
                                          :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="mobile_number" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('mobile_number')">
                            <b-form-input v-model="formData.mobile_number"
                                          :placeholder="$t('mobile_number')"
                                          :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="marriage_status" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('marriage_status')">
                            <multi-selectbox v-model="formData.marriage_status"
                                             :multiple="false"
                                             :options="marriageStatusOptions"
                                             :validate-error="errors[0]">
                            </multi-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="children_available" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('children_available')">
                            <multi-selectbox v-model="formData.children_available"
                                             :multiple="false"
                                             :options="childrenAvailableOptions"
                                             :validate-error="errors[0]">
                            </multi-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="country" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('country')">
                            <country-selectbox v-model="formData.country"
                                             :validate-error="errors[0]">
                            </country-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="current_city" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('current_city')">
                            <city-selectbox v-model="formData.current_city"
                                            :validate-error="errors[0]">
                            </city-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="current_district" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('current_district')">
                            <district-outline-selectbox v-model="formData.current_district"
                                                        :city_id="formData.current_city"
                                                        :validate-error="errors[0]">
                            </district-outline-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="address" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('address')">
                            <b-form-input v-model="formData.address"
                                          :placeholder="$t('address')"
                                          :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" lg="12" class="d-flex justify-content-end">
                    <b-button variant="primary" @click="saveForm">{{ $t('save') }}</b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>
<script>
// Template

// Components
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox"
import CountrySelectbox from "@/components/interactive-fields/CountrySelectbox"
import CitySelectbox from "@/components/interactive-fields/CitySelectbox"
import DistrictOutlineSelectbox from "@/components/interactive-fields/DistrictOutlineSelectbox";

//Pages

// Services

// Others
import {ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
    props: {
        graduateData: {
            type: Object,
            default: {}
        },
    },

    components: {
        ValidationObserver,
        ValidationProvider,

        MultiSelectbox,
        CountrySelectbox,
        CitySelectbox,
        DistrictOutlineSelectbox
    },

    data() {
        return {
            formData: {},
            marriageStatusOptions: [
                {
                    text: 'Evli',
                    value: 'Evli'
                },
                {
                    text: 'Bekar',
                    value: 'Bekar'
                }
            ],
            childrenAvailableOptions: [
                {
                    text: this.$t('available'),
                    value: 'Var'
                },
                {
                    text: this.$t('none'),
                    value: 'Yok'
                }
            ],
        }
    },
    created() {
        this.setData()
    },
    methods: {
        setData(){
            this.formData = {
                personal_email: this.graduateData.personal_email,
                graduate_email: this.graduateData.email,
                mobile_number: this.graduateData.mobile_number,
                marriage_status: this.graduateData.marriage_status,
                children_available: this.graduateData.children_available,
                address: this.graduateData.address
            }
        },

        async saveForm(){
            const isValid = await this.$refs.formValidate.validate();
            if (isValid) {
                this.$toast.success(this.$t('successfully_saved'))
                this.$emit('updateSuccess')
            }
        }
    }
}
</script>

