<template>
    <div>
        <b-row>
            <b-col cols="12" lg="12">
                <b-card :header="$t('personal_info')" class="soft mb-5">
                    <b-row>
                        <b-col cols="12" lg="12">
                            <b-row>
                                <b-col cols="12" lg="6">
                                    <div class="label-as-input-div">
                                        <label>{{ $t('personal_email') }}</label>
                                        <div>{{ graduateData.personal_email }}</div>
                                    </div>
                                </b-col>
                                <b-col cols="12" lg="6">
                                    <div class="label-as-input-div">
                                        <label>{{ $t('current_district') }}</label>
                                        <div>{{ graduateData.district }}</div>
                                    </div>
                                </b-col>
                                <b-col cols="12" lg="6">
                                    <div class="label-as-input-div">
                                        <label>{{ $t('graduate_email') }}</label>
                                        <div>{{ graduateData.email }}</div>
                                    </div>
                                </b-col>
                                <b-col cols="12" lg="6">
                                    <div class="label-as-input-div">
                                        <label>{{ $t('current_city') }}</label>
                                        <div>{{ graduateData.city }}</div>
                                    </div>
                                </b-col>
                                <b-col cols="12" lg="6">
                                    <div class="label-as-input-div">
                                        <label>{{ $t('mobile_number') }}</label>
                                        <div>{{ graduateData.mobile_number }}</div>
                                    </div>
                                </b-col>
                                <b-col cols="12" lg="6">
                                    <div class="label-as-input-div">
                                        <label>{{ $t('country') }}</label>
                                        <div>{{ graduateData.country }}</div>
                                    </div>
                                </b-col>
                                <b-col cols="12" lg="6">
                                    <div class="label-as-input-div">
                                        <label>{{ $t('marriage_status') }}</label>
                                        <div>{{ graduateData.marriage_status }}</div>
                                    </div>
                                </b-col>
                                <b-col cols="12" lg="6">
                                    <div class="label-as-input-div">
                                        <label>{{ $t('address') }}</label>
                                        <div>{{ graduateData.address }}</div>
                                    </div>
                                </b-col>
                                <b-col cols="12" lg="6">
                                    <div class="label-as-input-div">
                                        <label>{{ $t('children_available') }}</label>
                                        <div>{{ graduateData.children_available }}</div>
                                    </div>
                                </b-col>
                                <b-col cols="12" lg="12">
                                    <b-button class="mt-4" variant="primary" @click="$emit('updateClick',['personal_info'])">{{ $t('edit') }}</b-button>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
// Template

// Components

//Pages

// Services

// Others

export default {
    props: {
        graduateData: {
            type: Object,
            default: {}
        },
    },

    components: {

    },

    data() {
        return {

        }
    },
    created() {

    },
    methods: {

    }
}
</script>

